<template>
	<div class="full-height">
		<div
			class=""
		>
			<div
				class="justify-space-between"
			>
				<div class="flex-1 bg-white pa-10 mr-10">
					<h6 class="under-line-identify">계정정보</h6>
					<table class="table th-left td-left">
						<col width="120px">
						<col width="auto">
						<col width="120px">
						<col width="auto">
						<tbody>
						<tr>
							<th>아이디</th>
							<td>
								{{ user.account_id }}
							</td>
							<th>가입일</th>
							<td class="position-relative">
								{{ item.join_date }}
							</td>
						</tr>
						<tr>
							<th>이름 <span class="color-red">*</span></th>
							<td>
								{{ user.agency_name }}
							</td>
							<th>휴대폰 번호</th>
							<td>

								<div  class="justify-space-between items-center">
									{{ item.account_phone_number }}

									<template
									>
										<button
											v-if="user.sms_auth_uid"
											class="bg-identify pa-5-10"

										>인증완료</button>
										<button
											v-else
											class="bg-identify pa-5-10"

											@click="onSms"
										>SMS 인증</button>
									</template>
								</div>
							</td>
						</tr>
						<tr>
							<th>비밀번호 변경</th>
							<td colspan="3" class="text-right">
								<button
									class="bg-identify pa-5-10"

									@click="onPassword"
								>비밀번호 변경</button>
							</td>
						</tr>
						<tr>
							<th>PIN</th>
							<td colspan="3" class="text-right">
								<button
									class="bg-identify pa-5-10"

									@click="onPin"
								>PIN 설정</button>
							</td>
						</tr>
						</tbody>
					</table>
				</div>

				<div class="flex-1 bg-white pa-10">
					<div v-if="user.role == 'agency'">
						<div class="justify-space-between">
							<h6 class="under-line-identify">부관리자 정보</h6>
							<button
								v-if="false"
								@click="addSub"
							><v-icon class="color-blue">mdi-plus-circle</v-icon></button>
						</div>
						<table class="table th-left td-left">
							<col width="auto">
							<tbody>
							<tr>
								<td>
									<ul>
										<li
											v-for="(sub, s_index) in items_sub"
											:key="'sub_' + s_index"
											class="mb-10"
										>
											{{ sub.name }}
											<button
												@click="removeSub(s_index)"
											><v-icon class="color-red">mdi-minus-circle</v-icon></button>
										</li>
									</ul>
									<div>
										부관리자 등록시 로그인 이후 해당 휴대폰 번호로 인증을 진행할 수 있습니다
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>

		<div
			v-if="false"
			class="mt-30 justify-center"
		>
			<button
				class="bg-identify pa-10-20 mr-10"
				@click="save"
			>저장</button>
		</div>
		<DaumPost
			:overlay="is_post"
			:config="daum_config"
			@callBack="addPost"
		></DaumPost>

		<Modal
			:is_modal="is_password"
			:option="modal_option"

			width="320px"
			height="240px"

			@click="putPassword"
			@close="clearPassword"
			@cancel="clearPassword"
		>
			<div
				slot="modal-title"
				class="pa-10 text-center bg-white under-line"
			><h6>비밀번호 변경</h6></div>
			<div
				slot="modal-content"
			>
				<div>
				<input
					v-model="item_password.account_password_old"
					type="password"
					class="box pa-10 width-100" placeholder="기존 비밀번호"
				/>
				</div>
				<div class="mt-10">
				<input
					v-model="item_password.account_password"
					type="password"
					class="box pa-10 width-100" placeholder="신규 비밀번호"
				/>
				</div>
				<div class="mt-10">
				<input
					v-model="item_password.account_password_confirm"
					type="password"
					class="box pa-10 width-100" placeholder="비밀번호 확인"
				/>
				</div>
			</div>
		</Modal>

		<AgencySmsConfirm
			v-if="is_on_sms"
			:user="user"
			:type="'auth'"
		></AgencySmsConfirm>
	</div>
</template>

<script>

import DaumPost from "@/components/Daum/DaumPost";
import '@toast-ui/editor/dist/toastui-editor.css';
import Modal from "@/components/Modal";
import AgencySmsConfirm from "./AgencySmsConfirm";

export default {
	name: 'MypageAgency'
	, components: {AgencySmsConfirm, DaumPost, Modal}
	, props: ['Axios', 'user', 'codes', 'rules', 'date', 'TOKEN']
	, data: function(){
		return {
			program: {
				name: '통합 관리자 계정 정보'
				, top: true
				, title: true
				, bottom: false
			}
			,item: {
				agency_type: ''
				,business_type: 'B002001'
				,bank_code: ''
				,join_date: this.date.getToday('-')
				,delivery_type: '0'
				,shop_return: ''
			}
			, item_pg: {

			}
			,is_data_pick: false
			,is_modal: false
			,is_post: false
			,daum_config: {
				width: '360px'
			}
			,modal_option: {
				title: false
				,bottom: true
				, top: true
			}
			, is_password: false
			, item_password: {
				account_password: ''
				, account_password_confirm: ''
				, account_password_old: ''
			}
			, upload_files: []
			, item_logo_img: null
			, item_upload_logo_img: {}
			, items_sub: []
			, is_on_sms: false
		}
	}
	,computed: {
		logo_img_name: function(){
			let name = '로고 이미지'
			return name
		}
		, sales_fee: function(){
			let t = 0

			t += Number(this.item.sales_fee)

			if(this.item.upper_sales_fee){
				t += Number(this.item.upper_sales_fee)
			}

			if(this.item_pg.uid){
				t += Number(this.item_pg.pg_fee)
			}

			return t
		}
		, text_pg_title: function(){
			let t = ''
			switch (this.user.agency_type){
				case  'A001001':
					t = '보유 PG 정보'
					break
				case 'A001002':
					t = 'PG 공급가 정보'
					break
				case 'A001003':
					t = '결제 PG 정보'
					break
			}
			return t
		}
		, account_sub: function(){
			let t = []
			this.items_sub.filter( (item) => {
				if(item.name && item.phone){
					t.push(item)
				}
			})
			return t.length > 0 ? JSON.stringify(t) : ''
		}
	}
	, methods: {
		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getAgency'
					,data: {
						agency_uid: this.user.uid
					}
					, skip: true
				})
				if(result.success){
					this.item = result.data.info
					if(result.data.pg_info){
						this.item_pg = result.data.pg_info
					}
					this.item_upload_logo_img = {
						src: this.item.shop_logo_view
						, name: this.item.shop_logo_view
						, type: 'image'
					}
					this.item_logo_img = ''
					let account_sub = result.data.info.account_sub
					if(account_sub){
						account_sub = JSON.parse(account_sub)
					}else{
						account_sub = [
							{ name: '', phone: ''}
						]
					}
					this.items_sub = account_sub
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, save: async function(){
			try{

				this.$bus.$emit('on', true)

				if(this.item_logo_img){
					this.$set(this.item, 'item_logo_img', this.item_logo_img)
				}

				const result = await this.$request.init({
					method: 'post'
					,url: 'management/putAgencyMyInfo'
					,data: {
						bank_email: this.item.bank_email
						, pg_code: this.item.pg_code
						, pgMerchNo: this.item.pgMerchNo
						, pgMerchName: this.item.pgMerchName
						, pg_fee: this.item.pg_fee
						, item_logo_img: this.item.item_logo_img
						, account_sub: this.account_sub
					}
				})
				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					await this.getData()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		,daumPost: function () {
			this.is_post = true
		}
		, addPost: function (call) {

			this.$set(this.item, 'shop_zip_code', call.zonecode)
			this.$set(this.item, 'shop_address', call.address)

			this.is_post = false
		}
		, onPassword: function(){
			this.is_password = true
		}

		, putPassword: async function(){
			try{
				const result = await this.$request.init({
					method: 'post'
					, url: 'management/putPassword'
					, data: {
						account_type: this.user.account_type_code
						, account_uid: this.user.account_uid
						, account_password_old: this.item_password.account_password_old
						, account_password: this.item_password.account_password
						, account_password_confirm: this.item_password.account_password_confirm
					}
				})
				if(result.success){
					this.$bus.$emit('notify',{ type: 'success', message: this.$language.common.success})
					this.clearPassword()
				}else{
					throw result.message
				}
			}catch(e){
				console.log(e)
				this.$bus.$emit('notify',{ type: 'error', message: e})
			}
		}
		, clearPassword: function(){
			this.is_password = false
			this.item_password = {
				account_password: ''
				, account_password_confirm: ''
				, account_password_old: ''
			}
		}
		, setFile2: function(e){
			// console.log('setFile2', e)

			for(let file of e){
				console.log(`file` , file)
				this.item_logo_img = file

				const reader = new FileReader()
				let data = {
					name: file.name
					, size: file.size
					, type: file.type
				}

				reader.onload = (e) => {
					console.log('reader.onload')
					data.src = e.target.result
					this.item_upload_logo_img = data
				}

				reader.readAsDataURL(file)
			}
		}
		, removeFile: async function(){
			if(this.item_logo_img){

				if(this.item.shop_logo){
					this.item_upload_logo_img = {
						src: this.item.shop_logo
						, name: this.item.shop_logo
						, type: 'image'
					}
					this.item_logo_img = null
				}else{
					this.item_upload_logo_img = {}
					this.item_logo_img = null
				}
			}else{
				if(confirm("삭제하시겠습니까?")){

					try{
						const result = await this.$request.init({
							method: 'post'
							,url: 'management/postShopLogoDelete'
							,data: {
								shop_uid: this.item.uid
							}
						})

						if(result.success){
							this.item_upload_logo_img = {}
							this.item_logo_img = null
						}else{
							this.$bus.$emit('notify', { type: 'error', message: result.message })
						}
					}catch (e) {
						console.log(e)
					}
				}
			}
		}
		, addSub: function(){
			this.items_sub.push({
				name: ''
				, phone: ''
			})
		}
		, removeSub: function(s_index){
			this.items_sub.splice(s_index, 1)
		}
		, onSms: function(){
			this.is_on_sms = true
		}

		, onPin: function(){
			this.$bus.$off('pinCallback')

			this.$bus.$emit('onPin', {
				type: this.user.is_pin > 0 ? 'check' : 'new'
			})

			this.$bus.$on('pinCallback', (call) => {
// console.log('pinCallback', call)
				switch (call.option.type){
					case 'new':
						this.postPin(call)
						break;
					case 'check':
						this.postPinCheck(call)
						break;
				}
			})
		}
		, postPinCheck: async function(data){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$request.init({
					method: 'post'
					, url: 'management/postPinCheck'
					, data: {
						account_id: this.user.account_id
						, pin: data.pin
					}
				})
				if(result.success){
					this.onPinUpdate()
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPinUpdate: function(){
			this.$bus.$off('pinCallback')

			this.$bus.$emit('onPin', {
				type: 'update'
			})
			this.$bus.$on('pinCallback', (call) => {
// console.log('pinCallback', call)
				switch (call.option.type){
					case 'update':
						this.postPin(call)
						break;
				}
			})
		}
		, postPin: async function(data){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$request.init({
					method: 'post'
					, url: data.option.type == 'new' ? 'auth/postPin' : 'auth/postPin'
					, data: {
						account_id: this.user.account_id
						, pin: data.pin
						, pin_confirm: data.pin_confirm
					}
				})
				if(result.success){
					this.$bus.$emit('updateUser', result.data)
					this.$bus.$emit('notify', { type: 'success', message: result.message})
					this.$bus.$emit('offPin')
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>

<style>
.width-fee { width: 60px !important; text-align: right;}
.v-btn__content { color: #333 !important;}
.theme--light.v-icon {
	color: #bbb;
}

.item_close {
	position: absolute; right: 10px; top: 10px
}
</style>
